import { Alert, Button, CircularProgress, Dialog, DialogActions, DialogContent, Snackbar, TextField, Typography } from "@mui/material";
import { useEffect, useRef, useState } from 'react';

let oldsid;

export function LoginDialog({socket,isloggedin,setloggedin,ismanager,user}) {
    const [username,setUsername] = useState('')
    const [password,setPassword] = useState('')
    const [sid,setSid] = useState(false)
    const [errormessage,setErrormessage] = useState(false)
    const [showdialog,setShowdialog] = useState(false)

    useEffect(() => {
        if (sessionStorage.getItem('username') && sessionStorage.getItem('pwd')) {
            setUsername(sessionStorage.getItem('username'))
            setPassword(sessionStorage.getItem('pwd'))
            handleLogin()
        } else {
            setShowdialog(true)
        }
  

    },[isloggedin])
    
    
    useEffect(() => {
        if (socket)
        socket.on('connect',() => {
            console.log('connect',socket.id)
            if (oldsid) sendnewsid()
        })  
    },[socket,sid])
    
    
    const sendnewsid = () => {
        console.log('oldsid',oldsid,'newsid',socket.id)
            socket.emit('sidchange',{oldsid:oldsid,newsid:socket.id}, data => {
                console.log(data)
                if (data && !data.loggedin)
                    setloggedin(false)
            })
    }


    const handleEnter = (e) => {
        if (e.key == 'Enter') handleLogin()
    }

    const handleLogin = () => {
        const uname = username || sessionStorage.getItem('username')
        const pwd = password || sessionStorage.getItem('pwd')
        if (!uname || !pwd) setShowdialog(true)
        socket.emit('login',{username:uname,password:pwd},data => {
            if (data.loggedin) {
                setErrormessage(false)
                setloggedin(true)
                sessionStorage.setItem('username',uname)
                sessionStorage.setItem('pwd',pwd)
                ismanager(data.ismanager)
                user(uname)
                setUsername('')
                setPassword('')
                setSid(socket.id)
                oldsid = socket.id
                console.log('sid set',socket.id)
            } else {
                setShowdialog(true)
                setloggedin(false)
                setErrormessage(data.errorreason)
                setSid(false)
            }
        })
    }

    return (<>
    
        <Dialog open={showdialog} onClose={e => handleLogin()} 
        disableEscapeKeyDown={true}>
            <DialogContent>Login</DialogContent>
            <DialogContent>
            <TextField
                    sx={{marginBottom:'10px'}}
                   label="Benutzername"
                   value={username || ''}
                   onChange = {(e) => setUsername(e.target.value)}
                 />
               <br/>
                 <TextField
                   type="password"
                   label="Passwort"
                   value={password || ''}
                   onChange = {(e) => setPassword(e.target.value)}
                   onKeyPress = {(e) => handleEnter(e)}
                   />
            {!!errormessage && <Alert severity="error">{errormessage}</Alert>}
            </DialogContent>
            <DialogActions>
                <Button onClick={e => handleLogin()} variant='contained' disabled={!username || !username.includes(', ')  || !password}>Anmelden</Button>
            </DialogActions>


        </Dialog>
        </>
    )

}