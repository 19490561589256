import { Box, Container, Divider, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { useEffect, useState } from "react"
import io from 'socket.io-client';
import rdhslogo from './rdhslogo.png';
import './overview.css'


const ENDPOINT = "https://campus.rdheinsberg.de/";


export function PrintPage() {
    const [socket,setSocket] = useState()
    const [data,setData] = useState({})


    useEffect(() => {
        if (!socket){
          setSocket(io(ENDPOINT))
          console.log('connecting socket')
        }
        },[])

    useEffect(() => {
        if (socket) {
            socket.emit('get uebersicht',{},data => {
                console.log('set data')
                setData(data)
              })     
        }
    },[socket])


    return (
        <>
        <div className='printlogo' style={{position:'fixed',top:'0',left:'0',maxWidth:'10%'}}>
          <img src={rdhslogo} alt="" style={{maxWidth:'100%'}}/>
        </div>
        {Object.keys(data).length && 
        <Container>
        <Box className='intro'>

        <Typography variant='h4' className='intro'>Aktuelle Formate für euch im RDHS-Campus </Typography>
        <Typography variant="caption">(Stand {data.stand})</Typography>
        </Box>
        <Paper className='katbox'>
            <Typography variant='h4' align='center' className="kattitle">Körperliche Fitness</Typography>  
            {data?.koerperliche_fitness.map(k => <Kurs kurs={k}/> )}
        </Paper>
        <Paper className='katbox'>
            <Typography variant='h4' align="center" className="kattitle">Methodische Fitness</Typography>  
            {data?.methodische_fitness.map(k => <Kurs kurs={k}/> )}
        </Paper>
        <Paper className='katbox'>
            <Typography variant='h4' align="center" className="kattitle">Seelische Fitness</Typography>  
            {data?.seelische_fitness.map(k => <Kurs kurs={k}/> )}
        </Paper>
        </Container>
        }
        </>
    )
}



function Kurs({kurs}) {

    return (
        <Container className="kurs">
        {!!kurs.termine.length && <>
        <Box className='onekurs'>  <Typography variant='h6'>{kurs.kurs}</Typography> </Box>
        {/* <Box>  <Typography variant='caption' dangerouslySetInnerHTML={{__html:kurs.text}} ></Typography> </Box> */}
        <Box>
                    <Table size="small">
                    <TableHead>
                        <TableRow><TableCell>von</TableCell><TableCell>bis</TableCell><TableCell>Anmeldung bis</TableCell>
                        <TableCell style={{ width: 200 }}>Ort</TableCell><TableCell>freie Plätze</TableCell></TableRow>
                    </TableHead>
                    {kurs?.termine.map(t => <Termine termin={t}/>)}
                    </Table>
        </Box>
        <Divider/>
        </>}
        </Container>
    )

}




function Termine({termin}) {

    return (
           <TableBody>
                <TableCell>{termin[2]}</TableCell>
                <TableCell>{termin[3]}</TableCell>
                <TableCell>{termin[4]}</TableCell>
                <TableCell>{termin[5]}</TableCell>
                <TableCell align="center">{termin[7]}</TableCell>
            </TableBody>
    )
}