import { Button } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import TypeIt from "typeit-react";
import './App.css';
import parse from 'html-react-parser'

export function _Typer({text,name,onfirstDone,onfurtherDones,cln,noparse}) {
    const [options,setOptions] = useState({speed:0,afterComplete:(instance) => checkClicks()})
    const [done,setDone] = useState(false)
    const [inst,setInst] = useState(null)

    useEffect(() => {
        setDone(false)
        //setDone(true)
        //checkClicks()
        if (inst) {
            // inst.flush()
            // inst.reset()
        }
     },[text])

    const checkClicks = () => {
        if (!done) {
            setDone(true)    
            if (inst) {
            // inst.flush()
            // inst.reset() 
            }
            onfirstDone(true)
        } else {
            onfurtherDones(true)
        }
    }


 
    return (
        <div key={'typer'+text} className={cln} onClick={e => checkClicks()} style={{zIndex:1000,position:'relative'}}>
        {done? 
        < >{text && parse(text)}</>
        :
        <TypeIt options={options} getBeforeInit={(instance) => {
                setInst(instance);
                return instance;
            }}
        >
            {name && <div style={{fontSize:'3rem',textAlign:'center'}}>Hallo {name},</div>}
            {text && parse(text)}
        </TypeIt>
        }
        </div>
    )
}


export function Typer({text,name,onfirstDone,onfurtherDones,cln,noparse}) {


    useEffect(()=> {
        if (name) {
            setTimeout(() => onfirstDone(true),2000)
        } else if (text) {
            setTimeout(() => onfirstDone(true),500)
        }
    },[text,name])

    const checkClicks = () => {
            onfirstDone(true)
            onfurtherDones(true)
    }


    return (
        <div key={'typer'+text} className={cln} onClick={e => checkClicks()} style={{zIndex:1000,position:'relative'}}>
        {name && <div style={{fontSize:'3rem',textAlign:'center'}}>Hallo {name},</div>}
        {text && parse(text)}
        </div>
    )

}

