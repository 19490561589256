import { Add, AddCircle, CheckBox, Delete, DeleteOutline, ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Box, Button, Checkbox, CircularProgress, Dialog, DialogContent, DialogTitle, Divider, FormControlLabel, InputLabel, Paper, Tab, Tabs, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';


const accstyle= {backgroundColor:'#57a3fb54',marginBottom:'5px'}


const cats = ['seelische_fitness','koerperliche_fitness','methodische_fitness']

const modules = {
    toolbar: [
      [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
      [{size: []}],
      [{ 'align': [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, 
       {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image', 'video'],
      ['clean']
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  }

const formats = [
    'header', 'font', 'size', 'align',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video'
  ]





export function TextEdit({socket,textedit,close}) {
    const [texte,setTexte] = useState({})
    const [changes,setChanges] = useState(false)
    const [loading,setLoading] = useState(false)
    const [tab,setTab] = useState(0)
    const [stats,setStats] = useState([])

    useEffect(() => {
        if (textedit) {
            setLoading(true)
            socket.emit('get texte',{key:'full'},data => {
                setTexte(data.text)
                setLoading(false)
            })
            socket.emit('get statistik',{},data => {
                setStats(data)
            })
        }
    },[textedit])

    const changeetext = (k,v) => {
        setTexte({...texte,[k]:v})
        setChanges(true)
    }

    const changetext = (k,s,v) => {
        setTexte({...texte,[k]:{...texte[k],[s]:v}})
        setChanges(true)
    }

    const uploadChanges = () => {
        socket.emit('mod texte',{'key':'full','texte':texte}, data => {
            setChanges(false)
        })
    }

    const addkurs = (cat) => { 
        let c = texte[cat]
        let k = c?.kurse || []
        setTexte({...texte,[cat]:{...texte[cat],'kurse':[...k,{'id':0,'text':'Kursbeschreibung','titel':'Titel'}]}})
        setChanges(true)
    }

    const setKurs = (cat,i,key,v) => {
        let k = texte[cat]['kurse'] || []
        k[i][key] = v
        setTexte({...texte,[cat]:{...texte[cat],'kurse':k}})
        setChanges(true)
    }

    const delkurs = (cat,index) => {
        let k = texte[cat]['kurse'] || []
        k.splice(index,1)
        setTexte({...texte,[cat]:{...texte[cat],'kurse':k}})
        setChanges(true)
    }


    return (
        <Dialog open={textedit} onClose={e => close()} maxWidth='lg' fullWidth PaperProps={{style: {backgroundColor:'#def2ff'}}}>
            <Tabs value={tab} onChange={(e,v) => setTab(v)} indicatorColor="primary" textColor="primary" centered>
                <Tab label='Texte bearbeiten'/>
                <Tab label='Statistik'/>
            </Tabs>
            {tab == 0 &&
            <DialogContent>
                <Accordion style={accstyle} key={'einleitung'}>
                    <AccordionSummary expandIcon={<ExpandMore/>}>
                    <Typography>Einleitung {loading && <CircularProgress size={18}/>} </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {!loading && <ReactQuill  theme="snow"  defaultValue={texte?.einleitung || ''} onChange={v => changeetext('einleitung',v)}
                        preserveWhitespace={true}     modules={modules} formats={formats} />}
                    </AccordionDetails>
                    <AccordionActions>
                            <Button disabled={!changes} variant='contained' onClick={e => uploadChanges()}>Speichern</Button>
                    </AccordionActions>
                </Accordion>
                {cats.map((cat,i) => 
                
                
                        <Accordion style={accstyle} key={'cat_'+i}>
                            <AccordionSummary expandIcon={<ExpandMore/>}>
                            <Typography>{cat} {loading && <CircularProgress size={18}/>} </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>Text Startseite</Typography>
                                {!loading && <ReactQuill  theme="snow"  defaultValue={texte[cat]?.typer || ''} onChange={v => changetext(cat,'typer',v)}
                                preserveWhitespace={true}     modules={modules} formats={formats} />}
                                <Divider/>
                                <Typography>Text Popup</Typography>
                                {!loading && <ReactQuill  theme="snow"  defaultValue={texte[cat]?.dialog || ''} onChange={v => changetext(cat,'dialog',v)}
                                preserveWhitespace={true}  modules={modules} formats={formats} />}
                                <Divider/>
                                <Box style={{display:'flex',flexFlow:'row'}}>
                                <Typography>Verknüpfte Kurse</Typography>
                                <AddCircle onClick={e => addkurs(cat)}/>

                                </Box>
                                {!loading && texte[cat]?.kurse && texte[cat]?.kurse.map((kurs,i) =>
                                <>
                                <Box sx={{display:'flex',flexFlow:'row',flexWrap:'wrap',alignItems:'flex-end' , justifyContent:'space-between',
                                            height:'25%',marginBottom:'25px',marginTop:'5px'}} >
                                    <TextField label='Titel' type='text' value={kurs.titel} onChange={e => setKurs(cat,i,'titel',e.target.value)} fullWidth/>
                                <Box sx={{display:'flex',flexDirection:'column', marginTop:'5px'}}>
                                    <TextField label='Aldente-ID' type='number' helperText='nicht buchbar bei 0' value={kurs.id} onChange={e => setKurs(cat,i,'id',e.target.value)}/>
                                    <FormControlLabel  control={<Checkbox checked={kurs?.blockworking || false} onClick={e => setKurs(cat,i,'blockworking',!kurs?.blockworking)}/>} label="Gesperrt für MA im Dienst" />
                            
                                </Box>
                                    <ReactQuill  theme="snow" height={'15%'} defaultValue={kurs?.text || ''} onChange={v => setKurs(cat,i,'text',v)}
                                        preserveWhitespace={true}  modules={modules} formats={formats} />
                                    <DeleteOutline onClick={e => delkurs(cat,i)}/>                                    
                                </Box>
                                <Divider/>
                                </> 
                                )}
                            </AccordionDetails>
                            <AccordionActions>
                                    <Button disabled={!changes} variant='contained' onClick={e => uploadChanges()}>Speichern</Button>
                            </AccordionActions>
                        </Accordion>
                )}
            </DialogContent>
            }
            {tab === 1 && 
            <DialogContent>
                Statistik seit 18.05.23
                {stats && stats.map((s,i) => 
                <div>{s.key}: {s.val}</div>
                )}
            </DialogContent>
            }
        </Dialog>
    )

}