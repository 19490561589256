import { Route, Routes } from "react-router-dom";
import { PrintPage } from "./printpage";
import App from "./App";




export function PreApp() {


    return (
        <>        
        <Routes>
            <Route path="uebersicht" element={<PrintPage />}/>
            <Route path='*' element={<App/>}/>
        </Routes>
        </>
    )
}