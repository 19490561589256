import logo from './logo.svg';
import './App.css';
import { Box, Button, CircularProgress, Container, Dialog, DialogActions, duration, Typography } from '@mui/material';
import Typewriter from 'typewriter-effect';
import { einleitung, circlegroups, koerp_fit, metho_fit, seel_gesund } from './texte';
import { Arc, Circle, Group, Image, Label, Layer, Line, Rect, Stage, Text, TextPath } from 'react-konva';
import { useEffect, useRef, useState } from 'react';
import useImage from 'use-image';
//import {useGlobal} from 'reactn';
import TypeIt from 'typeit-react';
import { Typer } from './typer';
import { describeArc, polarToCartesian } from './archelper';
import { isMobile } from 'react-device-detect';
import Konva from 'konva';
import { Katdialog } from './dialogs';
import rdhslogo from './rdhslogo.png';
import { Edit, InfoRounded, LogoutOutlined, PanToolAltOutlined, Square } from '@mui/icons-material';
import io from 'socket.io-client';
import { LoginDialog } from './login';
import { TextEdit } from './textedit';


const ENDPOINT = "https://campus.rdheinsberg.de/";
const VERSION = 1.1;

const cats = ['seelische_fitness','koerperliche_fitness','methodische_fitness']

function App() {
  const [maxW,setMaxW] = useState(window.innerWidth < window.innerHeight ? window.innerWidth : window.innerHeight)
  const [textdone,setTextdone] = useState('')
  const [info,setInfo] = useState(false)
  const [inlay,setInlay] = useState(-1)
  const [sid,setSid] = useState()
  // const [rot] = useState(0) //useState(isMobile ? 35: 0)
  const [circle,setCircle] = useState(null)
  const [arcs,setArcs] = useState([])
  const [currentdialog,setCurrentdialog] = useState(false)
  const rotval = {'seele':215,'koerp':95,'metho':335}
  const [socket,setSocket] = useState(false)
  const [isloggedin,setIsloggedin] = useState(false)
  const [ismanager,setIsmanager] = useState(false)
  const [username,setUsername] = useState('')
  const [edittexte,setEdittexte] = useState(false)
  const [texte,setTexte] = useState({})
  const [showpointer,setShowpointer] = useState(false)
  const arcrefs = useRef({})



  useEffect(() => {
    if (!socket){
      setSocket(io(ENDPOINT))
      console.log('connecting socket')
    }
    },[])


  useEffect(() => {
    if (isloggedin){
        setSid(socket.id)
        socket.emit('get texte',{},data => {
          setTexte(data.text)
          setArcs(data.arcs)
        })               
    }
  },[isloggedin])


  const logmegout = () => {
    console.log('logout')
    sessionStorage.removeItem('username')
    sessionStorage.removeItem('pwd')
    setIsloggedin(false)
  }
  


  const arccount = arcs.length


  const calc_size =(arc) => {
    if (arc == inlay) {
      return {scaleX:1,scaleY:1}
    } else {
      return {scaleX:1,scaleY:1}
    }
  }

  const degtorad = (angleInDegrees) =>{
    return (angleInDegrees) * Math.PI / 180.0;
  }

  const calc_pos = (arc) => {
    if (isMobile ) {
      const maxmove = window.innerHeight/2 - maxW/2
      const rot = -(360/arccount)+(arccount-1)*(360/arccount)*inlay
      const rcos =  Math.cos(degtorad(rot))
      const rsin =  Math.sin(degtorad(rot))
      const x =   maxmove * rsin;
      const y =   maxmove * rcos;     
      if (arc == inlay){
            const res = { x: window.innerWidth/2 - x, y:  window.innerHeight/2 - y}
        return res
      } else {
        const res =  {x:window.innerWidth/2 + x,y: window.innerHeight/2 +y}
        return res
      
      }
    } else {
      return {x:window.innerWidth/2,y:window.innerHeight/2}
    }
  }



  useEffect(() => {
    if (circle && inlay !== -1){
      circle.to({rotation: -(360/arccount)+(arccount-1)*(360/arccount)*inlay,duration: 1})
      arcs.forEach((arc,i) =>{ 
        //if (i == inlay) console.log({i:i,rot:arcrefs.current[i].attrs.rotation})
        arcrefs.current[i].to({...calc_size(i),...calc_pos(i),duration:1})
      })
      //circle.to({rotation: -90-(360/arccount)/2+(arccount-1)*(360/arccount)*inlay,duration: 2})
    }
    if (circle){
      
          const x=window.innerHeight/2 * Math.cos(degtorad(circle.getRotation()))- maxW/2 * Math.sin(degtorad(circle.getRotation()))
          const y=window.innerHeight/2 * Math.sin(degtorad(circle.getRotation()))+ maxW/2 * Math.cos(degtorad(circle.getRotation()))
          console.log({x:x,y:y})}
      setShowpointer(false)
  },[inlay])

  useEffect(() => {
    if (textdone == 'zoomin') {
      setTimeout(() => setTextdone('done'),2000)
    }

  },[textdone,circle])



  const calcArc = () => {
    const center = (maxW/2.1 + maxW/3.5) / 2
    const angle = (360/arccount)-5
    const radAngle = (angle-90) * Math.PI / 180.0;
    const dx = center * Math.cos(radAngle)
    const dy = center * Math.sin(radAngle)
    const result = ['M',center,0,'A',center,center,0,0,1,-dy,dx].join(" ")
    return result
  }


  const shouldshowpointer =  () => {
    if (inlay !== -1) {
      setShowpointer(true)
    }
  }



  const calcFontSize = () => {    
    if (isMobile) {
    return  20}
    else {
      const iw = window.innerHeight
      if (iw > 1080) {
        return 80
      } else if (iw > 900) {
        return 60
      }
    }
    return 40
  }

  const calcinntertextsize = () => {
    if (isMobile) {
      return 'sm'
    } else {
      return (maxW/3.7) * 2 +'px'
    }
  }



  return (
    <div className="App">
        <div style={{position:'fixed',top:'0',left:'0',maxWidth:'10%'}}>
          <img src={rdhslogo} alt="" style={{maxWidth:'100%'}}/>
        </div>

    {!isloggedin && <Box  display="flex"  justifyContent="center"  alignItems="center"  minHeight="100vh">
      <CircularProgress/>
      </Box>}

      {!isloggedin && socket && <LoginDialog isloggedin={isloggedin} socket={socket}  setloggedin={v => setIsloggedin(v)} ismanager={v => setIsmanager(v)} user={n => setUsername(n)}/>}
  {isloggedin && <>
    <Box style={{position:'fixed',top:0,right:0,zIndex:'2000',fontSize:'xx-large'}}>
    <InfoRounded  onClick={e => setInfo(true)}/>
    <LogoutOutlined onClick={e => logmegout()} />
    </Box>
    <Box  display="flex"  justifyContent="center" style={{marginBottom:'20%'}} alignItems="center"  minHeight="100vh">
      <Container maxWidth={calcinntertextsize()} id='hereisall'  style={{marginBottom: isMobile ? '20%' : '0',textShadow: '1px 1px 3px #001726',maxWidth:calcinntertextsize()}}>
            <div className='intro_text'>
            {(inlay < 0) && (textdone != 'done') && 
            <Typer cln={'firsttyper '+textdone} text={texte.einleitung} name={username.split(', ')[1]} onfurtherDones={e => console.log(e)} onfirstDone={e => setTextdone('zoomin')} />              
            }        
            {(textdone == 'done') && (inlay == -1) &&
              <Typer text={`<div style="font-size:xxx-large;text-align:center;">Wähle Deine Kategorie</div>`} onfirstDone={e => console.log(e)} onfurtherDones={e => setCurrentdialog(inlay)}/>
            }
            {arcs && arcs.map((arc,i) => 
                <>{inlay == i && <Typer text={texte[arc.kuerzel].typer} onfirstDone={e => setShowpointer(true)} onfurtherDones={e => setCurrentdialog(i)}/>}</>
            )}
            {showpointer && <PanToolAltOutlined className='tappointer' sx={{position:'fixed',top: window.innerHeight/2+'px',fontSize: '600%',
                left: window.innerWidth/2+'px'}}/> }
          </div>    
      </Container>
      </Box>  
    {texte && <Katdialog data={texte[arcs[currentdialog]?.kuerzel]} socket={socket} onclose={e => setCurrentdialog(false)}/>}
    <Stage className={'konva-box '+ textdone} width={window.innerWidth} height={window.innerHeight}>
    {textdone &&
      <Layer ref={(node) => setCircle(node)} 
            x={window.innerWidth/2} y={window.innerHeight/2} 
            //   x={0} y={0}
              offsetX={window.innerWidth/2} offsetY={window.innerHeight/2}
             >
              {/* <Circle x={100} y={100} radius={20} fill="blue" /> */}
        {arcs && arcs.map((arc,i) => 
                <Group ref={(node) => arcrefs.current[i] = node}
                x={i == 3 ? window.innerWidth/2 : window.innerWidth/2}
                y={i == 3 ? maxW/2 : window.innerHeight/2}
                //  offsetX={window.innerWidth/2} offsetY={window.innerHeight/2}
                rotation={(360/arccount)*i}
                //  {...calc_pos(i)}
                //  offsetX={0} offsetY={0}
                onClick={e => setInlay(i)} onTouchStart={e => setInlay(i)}>
                  <Arc innerRadius={maxW/3.5} outerRadius={maxW/2.1}  angle={(360/arccount)-5} 
                        fillRadialGradientColorStops={[0,arc['color'][0],1,arc['color'][1]]} 
                        fillRadialGradientEndRadius={maxW/1.5}
                        fillRadialGradientStartRadius={maxW/3.5}
                        stroke={inlay == i ? 'white' :'black'} 
                        
                        />
                  <TextPath  fill='#333' fontSize={calcFontSize()}  fontFamily='Arial' align='center' fontStyle='bold'
                    text= {arc.titel} data={calcArc()}
                    shadowBlur={isMobile ? 3: 4} shadowColor='white' shadowOffset={isMobile ? {x:-1,y:-1} : {x:-4,y:-2}}  
                    />


              </Group>  
        )}
    </Layer>
    }
    </Stage>
    {ismanager && <TextEdit socket={socket} textedit={edittexte} close={e => setEdittexte(false)}/>}
    {ismanager && <Edit style={{position:'fixed',bottom:'0',right:'0',maxWidth:'10%'}} onClick={e => setEdittexte(true)}/>}
    </> }
    {info && <InfoDialog open={info} close={e => setInfo(false)}/>}
    </div>
  );
}

function InfoDialog({open,close}) {

  return <Dialog open={open} onclose={e=> close()} PaperProps={{ sx: {zIndex:'6000',padding:'5px',backgroundColor:'#033658',color:'aliceblue'} }}>
    <Typography variant='body1'>Zum auswählen der Kategorie auf eines der Kreissegmente klicken.</Typography>
    <br/>
    <Typography variant='body1'>Für weitere Informationen und der Liste der angebotenen Kurse auf den Text klicken.</Typography>
    <DialogActions>
      <Button variant='outlined' onClick={e => close()}>ok</Button>
    </DialogActions>
  </Dialog>

}

export default App;
