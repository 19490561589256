import styled from "@emotion/styled";
import { Accordion, AccordionDetails, AccordionSummary, Alert, Backdrop, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, List, ListItem, Snackbar, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './App.css'
import parse from 'html-react-parser'
import { isMobile } from "react-device-detect";
import { InfoRounded } from "@mui/icons-material";


const accstyle= {backgroundColor:'#57a3fb54',marginBottom:'5px',color:'aliceblue'}


export function Katdialog({data,onclose,socket}) {
    const [expanded,setExpanded] = useState(false)
    


    return (
        <Dialog open={!!data} onClose={onclose} fullWidth maxWidth='lg' 
        PaperProps={{ sx: {zIndex:'6000',backgroundColor:'#033658',color:'aliceblue'} }}>
            <DialogTitle>
                <Typography variant='h4'>
                        {data?.titel}
                </Typography>
                </DialogTitle>
            <DialogContent>
                <Typography>{data?.dialog && parse(data.dialog)}</Typography>
        <br/>
        {data?.kurse && !!data.kurse.length && <Typography>Unsere Kurse:</Typography>}
            {data?.kurse &&  data.kurse.map((kurs,i) =>
                    <OneKurs key={'kursliste_'+i} socket={socket} kurs={kurs} expanded={i === expanded} expandme={e => setExpanded(i)}/>
            )}

        </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={e => onclose()}>schließen</Button>
            </DialogActions>
        </Dialog>

    )

}


function OneKurs({socket,kurs,expanded,expandme}) {
    const [termine,setTermine] = useState([])
    const [loading,setLoading] = useState(true)
    const [snack,setSnack] = useState(false)

    useEffect(() => {
        if (kurs.id) {
            load_termine()
            }
        else {
            setLoading(false)
        }
    },[])

    const load_termine = () => {
        socket.emit('get termine for',{'id':kurs.id},data => {
            setTermine(data)
            setLoading(false)
        })
    }

    const book = (id,dienste) => {
        console.log(id,dienste)
        socket.emit('book',{fbid:kurs.id,tid:id,dienste:dienste},data => {
         setSnack(data.result ? 'Buchung erfolgreich':'Buchung fehlgeschlagen')
        load_termine()}
        )
    }


    const datebuilder = (s,e) => {
        const sd =s.split(' ')[0]
        const st = s.split(' ')[1]
        const se = s.split(' ')[2]
        return (isMobile ?
            <>{sd.split('.')[0]}.{sd.split('.')[1]}</>
        :
            <>{sd}</>
         )
    }

    
    
    return (
        <Accordion expanded={expanded} onClick={e => expandme()} key={'kurs_'+kurs.titel} style={accstyle}>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                    <Typography variant="h5" >{kurs.titel} {loading && <CircularProgress size={18}/>}</Typography>
                    <Divider/>
            </AccordionSummary>
            <AccordionDetails>
                <Box sx={{display:'flex',flexFlow:'row',flexWrap:'wrap',justifyContent:'space-between'}}>
                <div sx={{with: kurs?.id > 0 ? '45%': '100%'}}>
                <Typography>{kurs?.text && parse(kurs.text)}</Typography>                
                </div>
                {kurs.id > 0 &&
                    <div sx={{with:'45%'}}>
                        {(termine.length) && (kurs.id !== 0) ? 
                            <Table size="small" dense>
                                        <TableHead style={{fontWeight:'bold'}}><TableRow >
                                                <TableCell style={{color:'aliceblue',fontWeight:'bold'}} align="center">Wann</TableCell>
                                                {!isMobile && <TableCell style={{color:'aliceblue',fontWeight:'bold',maxWidth:'25%'}} align="center">Wo</TableCell>}
                                                {!isMobile && <TableCell style={{color:'aliceblue',fontWeight:'bold'}} align="center">Deine Dienste
                                                <Tooltip title="Tag vorher, am Tag selbst, Tag danach"><InfoRounded fontSize="small"/></Tooltip>  
                                                </TableCell>}
                                                <TableCell style={{color:'aliceblue',fontWeight:'bold'}} align="center">freie Plätze</TableCell>
                                                <TableCell></TableCell>
                                        </TableRow></TableHead>
                                        <TableBody>
                                {termine.map((t,i) => 
                                            <TableRow key={'tablecell'+kurs.titel+i}>
                                                <TableCell style={{color:'aliceblue'}} align="center" >{datebuilder(t[2])}</TableCell>
                                                {!isMobile && <TableCell style={{color:'aliceblue',maxWidth:"25%"}} align="center" >{t[5].replace('Rettungswache','RW')}</TableCell>}
                                                {!isMobile && <TableCell style={{color:'aliceblue'}} align="center">{t[10].join(',')}</TableCell>}
                                                <TableCell style={{color:'aliceblue'}} align="center">{t[7]}</TableCell>
                                                <TableCell align="center"><Button disabled={kurs?.blockworking && (t[11] || t[12])} variant='contained' onClick={e => book(t[0],t[10])}>buchen</Button> </TableCell>
                                            </TableRow>
                                            )}
                                </TableBody>
                            </Table>
                        :
                        <Typography>Schon gebucht, oder keine Termine verfügbar<br/>
                            Im Zweifel bei Aldente checken
                        </Typography>
                        }

                    </div>
                }
                </Box>                                           

            </AccordionDetails>

            <Snackbar open={snack} autoHideDuration={6000} onClose={e => setSnack(false)}>
                <Alert onClose={e => setSnack(false)} severity={(snack && snack.includes('erfolgreich')) ? "success" : "error"}>
                        {snack}
                </Alert>
            </Snackbar>
        </Accordion>
    )

}
