


export const circlegroups = [
    {
      kuerzel : 'koerperliche_fitness',
      titel : 'Körperliche Fitness',
      color : ['#ffd400','#ffe566'],
      typer : <>
      Körperliche Fitness ist ein wichtiger Faktor, besonders im Rettungsdienst.
      Ein starker Körper ermöglicht es dir, Herausforderungen bei der Arbeit und im Alltag besser zu bewältigen.
      <br/>
      Stärke dich selbst für die Herausforderungen des Rettungsdiensts und für ein aktives, erfülltes Leben.
      </>
    },
    {
      kuerzel : 'methodische_fitness',
      titel : 'Methodische Fitness',
      color : ['#e64920','#e78065'],
      typer : <>Methodische Fitness ist entscheidend für den Erfolg am Arbeitsplatz.
      Ob du besser mit Veränderungen umgehen, dein Arbeits- und Tarifrecht verstehen oder Deine Aufgaben effektiver strukturieren möchtest -
      wir haben das richtige Angebot für Dich. Unsere Kurse zur methodischen Fitness bieten dir die Werkzeuge, die du brauchst,
      um deine Arbeit erfolgreicher und effizienter zu gestalten.<br/>
      
      Investiere in deine methodische Fitness.</>
    },
    {
      kuerzel : 'seelische_fitness',
      titel : 'Seelische Fitness',
      color : ['#f08213','#f3a75b'],
      typer : <>Seelische Gesundheit ist von unschätzbarem Wert. Ob es um Resilienz, den Umgang mit Krisen oder das Vermeiden von Burnout geht - wir haben die Lösung.
      Unsere Kurse zur seelischen Gesundheit bieten dir die Werkzeuge, die du benötigst, um dich und dein Wohlbefinden zu stärken.
      <br/>
      Melde dich jetzt an und starte deine Reise zu einem gesünderen, glücklicheren Selbst</>
    }
  ]


export const __einleitung = <>Wir möchten Dir eine individuelle Möglichkeit anbieten, Dich in Bereichen wie seelischer, körperlicher und methodischer Fitness zu verbessern. Unser Ziel ist es, Dich zu unterstützen und Dir eine positive Arbeitsumgebung zu bieten.
Deshalb bieten wir eine Auswahl an Kursen an, die helfen, Deine Fähigkeiten und Fertigkeiten zu verbessern und Dein Wohlbefinden zu steigern.
Wir freuen uns darauf, Dich auf diesem Weg zu unterstützen.<br/>
Viel Spaß bei der Auswahl!</>

export const einleitung = <>Herzlich willkommen,<br/>demnächst bieten wir Dir hier individuelle Möglichkeiten, Dich in Bereichen wie seelischer, körperlicher und methodischer Fitness zu verbessern. Unser Ziel ist es, Dich zu unterstützen und Dir eine positive Arbeitsumgebung zu bieten.
Dafür stellen wir eine Auswahl an Kursen zusammen, die dir helfen sollen, Deine Fähigkeiten und Fertigkeiten zu verbessern und Dein Wohlbefinden zu steigern.
Wir freuen uns darauf, Dich auf diesem Weg zu unterstützen.<br/> 
 </>


export const seel_gesund = <>Seelische Gesundheit ist von unschätzbarem Wert. Ob es um Resilienz, den Umgang mit Krisen oder das Vermeiden von Burnout geht - wir haben die Lösung.
Unsere Kurse zur seelischen Gesundheit bieten dir die Werkzeuge, die du benötigst, um dich und dein Wohlbefinden zu stärken.
<br/>
Melde dich jetzt an und starte deine Reise zu einem gesünderen, glücklicheren Selbst</>

export const metho_fit = <>Methodische Fitness ist entscheidend für den Erfolg am Arbeitsplatz.
Ob du besser mit Veränderungen umgehen, dein Arbeits- und Tarifrecht verstehen oder Deine Aufgaben effektiver strukturieren möchtest -
wir haben das richtige Angebot für Dich. Unsere Kurse zur methodischen Fitness bieten dir die Werkzeuge, die du brauchst,
um deine Arbeit erfolgreicher und effizienter zu gestalten.<br/>

Investiere in deine methodische Fitness.</>

export const koerp_fit = <>
Körperliche Fitness ist ein wichtiger Faktor, besonders im Rettungsdienst.
Ein starker Körper ermöglicht es dir, Herausforderungen bei der Arbeit und im Alltag besser zu bewältigen.
<br/>
Stärke dich selbst für die Herausforderungen des Rettungsdiensts und für ein aktives, erfülltes Leben.
</>




//Alt 

export const _einleitung = <><div>Wir möchten dir eine großartige Möglichkeit anbieten, dich in Bereichen wie seelischer, körperlicher und methodischer Fitness zu verbessern. Unser Ziel ist es, dich zu unterstützen und dir eine positive Arbeitsumgebung zu bieten.</div>
<div>Wir wissen, dass du immer dein Bestes gibst und dich ständig verbessern möchtest. Deshalb bieten wir dir eine Auswahl an Kursen, die dir helfen, deine Fähigkeiten und Fertigkeiten zu verbessern und dein Wohlbefinden zu steigern.</div>
<div>Investitionen in dich selbst können zu besseren Leistungen und Zufriedenheit im Arbeitsumfeld und auch in deinem Privatleben führen. Nutze diese Chance, um dich selbst weiterzuentwickeln und deine Ziele zu erreichen.</div>
<div>Wir freuen uns darauf, dich auf diesem Weg zu unterstützen.</div>
<div>Gemeinsam wollen wir das beste aus der RDHS holen; für für die Menschen, die sich auf uns verlassen, für deine Kollegen, und vor allem </div>
<div>..... </div>
</>



export const _seel_gesund = <>
<div>Seelische Gesundheit ist von unschätzbarem Wert. Ob es um Resilienz, den Umgang mit Krisen oder das Vermeiden von Burnout geht - wir haben die Lösung. </div>
<div>Unsere Kurse zur seelischen Gesundheit bieten dir die Werkzeuge, die du benötigst, um dich und dein Wohlbefinden zu stärken. </div>
<div>Lernen von erfahrenen Experten und in einer unterstützenden Umgebung kann dir helfen, dich besser auf Herausforderungen vorzubereiten und sie besser zu bewältigen. </div>
<div>Investiere in deine seelische Gesundheit und verbessere deine Fähigkeit, mit Stress und Belastungen umzugehen. </div>
<div>Melde dich jetzt an und starte deine Reise zu einem gesünderen, glücklicheren Selbst.`</div>
</>

export const _metho_fit = `Methodische Fitness ist entscheidend für den Erfolg am Arbeitsplatz. 
Ob du besser mit Veränderungen umgehen, dein Arbeits- und Tarifrecht verstehen oder deine Aufgaben effektiver strukturieren möchtest - 
wir haben das richtige Angebot für dich. Unsere Kurse zur methodischen Fitness bieten dir die Werkzeuge, die du brauchst, 
um deine Arbeit erfolgreicher und effizienter zu gestalten. Lernen von erfahrenen Experten und in einer unterstützenden Umgebung kann dir helfen, dich auf die Herausforderungen am Arbeitsplatz besser vorzubereiten und deine Fähigkeiten zu verbessern. 
Investiere in deine methodische Fitness und sieh, wie sich deine Arbeit verbessert. 
Melde dich jetzt an und starte deine Reise zu einem effektiveren Arbeitsstil.`

export const _koerp_fit = `Körperliche Fitness ist ein wichtiger Faktor, besonders im Rettungsdienst. 
Ein starker Körper ermöglicht es dir, Herausforderungen bei der Arbeit und im Alltag besser zu bewältigen. 
Unsere Kurse zur körperlichen Fitness bieten dir die Gelegenheit, deine körperliche Stärke und Ausdauer zu verbessern. 
Du lernst von erfahrenen Trainern und in einer motivierenden Umgebung. Investiere in deine körperliche Fitness und erlebe, 
wie sich deine Energie, deine Leistung und dein Selbstvertrauen verbessern. 
Stärke dich selbst für die Herausforderungen des Rettungsdiensts und für ein aktives, erfülltes Leben.`